export const removeEmptyFieldsWithDraft = function (obj) {
  if (Boolean(obj.start_dt) === false) delete obj.start_dt
  if (Boolean(obj.deadline_dt) === false) delete obj.deadline_dt
  obj.members.forEach(function (item, i, array) {
    if (Boolean(item.user_id) === false) array.splice(i, 1)
  })
  if (obj.members.length === 0) delete obj.members

  obj.not_allowed.forEach(function (item, i, array) {
    if (Boolean(item.text) === false) array.splice(i, 1)
  })
  if (obj.not_allowed.length === 0) delete obj.not_allowed

  obj.result.forEach(function (item, i, array) {
    if (Boolean(item.text) === false) array.splice(i, 1)
  })
  if (obj.result.length === 0) delete obj.result

  return obj
}
