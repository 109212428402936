<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Создание нового проекта"
    >
      <v-row>
        <v-col cols="4">
          <v-select
            v-if="visibleWorkflow"
            v-model="process_id"
            :items="items_process"
            item-value="id"
            item-text="name"
            label="Бизнес процесс"
            dense
          />
        </v-col>
        <v-col cols="4" />
        <v-col cols="4">
          <v-select
            v-model="fields.status"
            :items="items_status"
            item-value="code"
            item-text="name"
            label="Статус"
            readonly
            dense
          />
        </v-col>
      </v-row>

      <project-edit-form :fields="fields" @onProjectSave="saveData"/>

    </base-card>
  </v-container>
</template>

<script>
import ProjectEditForm from '../../components/form/ProjectEditForm'
import { removeEmptyFieldsWithDraft } from '../../function'
export default {
  name: 'ProjectCreate',
  components: { ProjectEditForm },
  data: () => ({
    fields: {
      name: '',
      customer_id: null, // заказчик
      executor_id: null, // исполнитель
      goal_id: null,
      goal_vector: '',
      vector_value: null,
      result: [
        { text: '', value: null }
      ],
      members: [
        { user_id: null, functional: '' }
      ],
      cost: null,
      deadline_dt: '',
      start_dt: '',
      status: 'draft',
      category: null,
      not_allowed: [
        { text: '' }
      ],
      finance_source: '',
      finance_article: '',
      fin_potential: null,
      fin_expected: null
    },
    process_id: null
  }),
  mounted () {
    this.getItems()
  },
  computed: {
    items_status: function () {
      return this.$store.state.project.items.status
    },
    items_process: function () {
      return this.$store.state.project.items.process
    },
    visibleWorkflow: function () {
      return this.$auth.check('root')
    }
  },
  methods: {
    async getItems () {
      // TODO вынести получение процесса по умолчанию в отдельный метод на бэке
      await this.$store.dispatch('project/GET_ITEMS')
      this.process_id = this.$store.state.project.items.default_process
    },
    async saveData (val) {
      if (!val.validate()) {
        return
      }
      this.$store.commit('OVERLAY_ON')
      if (this.fields.status === 'draft') {
        this.fields = removeEmptyFieldsWithDraft(this.fields)
      }
      const payload = this.fields
      payload.process_id = this.process_id
      await this.$store.dispatch('project/POST_PROJECT', payload)
        .then(response => {
          this.flashMessage.success({ title: 'Создание', message: 'Проект успешно создан' })
          this.$router.push({ path: `/projects/${response.id}/view` })
        }).catch(error => {
          const message = error.response.data.message || ''
          const title = error.response.data.title
          this.flashMessage.error({ title, message })
        }).finally(() => { this.$store.commit('OVERLAY_OFF') })
    }
  }
}
</script>

<style scoped>

</style>
