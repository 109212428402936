<template>
  <v-container
    id="data-tables"
    tag="section"
    fluid
  >
    <base-card
      color="indigo"
      icon="mdi-briefcase"
      inline
      class="px-5 py-3"
      title="Проекты"
    >
      <v-layout row wrap>
        <v-flex xs2 class="mx-2">
          <v-switch
            v-model="closed"
            label="Закрытые проекты"
            class="ml-12"
            @change="changeClosed"
            dense
          />
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs6 lg2 class="px-2">
          <v-text-field
            v-model="filterSearch"
            append-icon="mdi-magnify"
            class="ml-auto"
            label="Поиск"
            style="max-width: 250px;"
            dense
            clearable
          />
        </v-flex>
        <v-flex xs6 lg2 class="px-2">
          <v-select
            v-model="filterPriority"
            :items="items"
            item-text="category"
            item-value="category"
            label="Категория"
            clearable
            dense
          ></v-select>
        </v-flex>
        <v-flex xs6 lg2 class="px-2">
          <v-select
            v-model="filterStatus"
            :items="items"
            item-text="status"
            item-value="status"
            label="Статус"
            clearable
            dense
          ></v-select>
        </v-flex>
        <v-flex xs6 lg2 class="px-2">
          <date-picker v-model="filterStartDate" dense clearable label="Начальная дата" @click:clear="filterStartDate = null"/>
        </v-flex>
        <v-flex xs6 lg2 class="px-2">
          <date-picker v-model="filterEndDate" dense clearable label="Конечная дата" @click:clear="filterEndDate = null"/>
        </v-flex>
        <v-flex xs6 lg2 class="px-2">
          <v-text-field
              v-model="filterUser"
              append-icon="mdi-magnify"
              class="ml-auto"
              label="Руководитель проекта"
              clearable
              dense
          />
        </v-flex>
      </v-layout>

      <v-divider class="mt-3" />

      <v-data-table
        v-if="items.length>0"
        :headers="headers"
        :items="items"
        :search.sync="filterSearch"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :options.sync="pagination"
        @click:row="rowClick"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            text icon color="accent" dark class="mr-2" @click.stop="$router.push({ path: `/projects/${item.id || ''}/plan` })"
            :disabled="item.plan === null"
            v-tooltip.bottom="`План проекта`"
          ><v-icon>mdi-view-list</v-icon>
          </v-btn>
        </template>

      </v-data-table>
    </base-card>
  </v-container>
</template>

<script>
export default {
  name: 'ProjectList',
  data: () => ({
    sortBy: 'id',
    sortDesc: false,
    closed: false,
    // filters: {
    //   category: '',
    //   status: '',
    //   startDate: '',
    //   endDate: ''
    // },
    items: []
    // search: ''
  }),
  mounted () {
    this.getData()
  },
  computed: {
    headers: function () {
      return [
        { text: '№', value: 'id' },
        { text: 'Название проекта', value: 'name', width: '45%' },
        {
          text: 'Срок',
          value: 'deadline_dt',
          filter: value => {
            if (!this.filterStartDate && !this.filterEndDate) return true
            if (this.filterStartDate && this.filterEndDate) {
              return this.$moment(value, 'DD.MM.YYYY') >= this.$moment(this.filterStartDate) && this.$moment(value, 'DD.MM.YYYY') <= this.$moment(this.filterEndDate)
            }
            if (this.filterStartDate) return this.$moment(value, 'DD.MM.YYYY') >= this.$moment(this.filterStartDate)
            if (this.filterEndDate) return this.$moment(value, 'DD.MM.YYYY') <= this.$moment(this.filterEndDate)
          }
        },
        {
          text: 'Категория',
          value: 'category',
          filter: value => {
            if (!this.filterCategory) return true
            return value === this.filterCategory
          }
        },
        {
          text: 'Статус',
          value: 'status',
          filter: value => {
            if (!this.filterStatus) return true
            return value.toLowerCase() === this.filterStatus.toLocaleLowerCase()
          }
        },
        {
          text: 'Руководитель проекта',
          value: 'executor',
          filter: value => {
            if (!this.filterUser || this.filterUser.length === 0) return true
            return value.toLowerCase().indexOf(this.filterUser.toLowerCase()) !== -1
          }
        },
        { text: 'Заказчик', value: 'customer' },
        { sortable: false, value: 'actions' }
      ]
    },
    filterStatus: {
      get: function () {
        return this.$store.state.project.filters.status
      },
      set: function (value) {
        this.$store.commit('project/SET_FILTERS', { obj: 'status', val: value })
      }
    },
    filterPriority: {
      get: function () {
        return this.$store.state.project.filters.priority
      },
      set: function (value) {
        this.$store.commit('project/SET_FILTERS', { obj: 'priority', val: value })
      }
    },
    filterStartDate: {
      get: function () {
        return this.$store.state.project.filters.startDate
      },
      set: function (value) {
        this.$store.commit('project/SET_FILTERS', { obj: 'startDate', val: value })
      }
    },
    filterEndDate: {
      get: function () {
        return this.$store.state.project.filters.endDate
      },
      set: function (value) {
        this.$store.commit('project/SET_FILTERS', { obj: 'endDate', val: value })
      }
    },
    filterUser: {
      get: function () {
        return this.$store.state.project.filters.user
      },
      set: function (value) {
        this.$store.commit('project/SET_FILTERS', { obj: 'user', val: value })
      }
    },
    filterSearch: {
      get: function () {
        return this.$store.state.project.filters.search
      },
      set: function (value) {
        this.$store.commit('project/SET_FILTERS', { obj: 'search', val: value })
      }
    },
    pagination: {
      get: function () {
        return this.$store.state.project.pagination
      },
      set: function (value) {
        this.$store.commit('project/SET_PAGINATION', value)
      }
    }
  },
  methods: {
    async getData () {
      const data = await this.$store.dispatch('project/GET_PROJECTS')
      this.items = data.projects
    },
    rowClick (event) {
      this.$router.push({ path: `/projects/${event.id}/view` })
    },
    changeClosed () {
      const payload = {
        closed: this.closed ? true : undefined
      }
      this.$store.dispatch('project/GET_PROJECTS', payload)
        .then(response => {
          this.items = response.projects
        })
    }
  }
}
</script>

<style scoped>

</style>
