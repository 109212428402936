<template>
 <div>
   <v-btn v-if="visibleProjectBtn" color="primary" class="ma-1" outlined small :to="`/projects/${id}/view`">Проект</v-btn>
   <v-btn v-if="visiblePlanBtn" color="primary" class="ma-1" outlined small :to="`/projects/${id}/plan`">План</v-btn>
   <v-btn v-if="visibleEditBtn" color="primary" class="ma-1" outlined small :to="`/projects/${id}/edit`"><v-icon left>mdi-pencil</v-icon>Изменить</v-btn>
   <v-btn v-if="visibleControlPoint" color="primary" class="ma-1" outlined small :to="`/projects/${id}/cp`">Контрольные точки</v-btn>
   <v-btn v-if="visibleFinalReportBtn" color="primary" class="ma-1" outlined small :to="`/projects/${id}/final`">Итоговый отчет</v-btn>
 </div>
</template>

<script>
export default {
  name: 'ProjectNavigation',
  props: {
    executor_id: Number,
    creator_id: Number,
    whom_id: Number,
    customer_id: Number,
    status: String
  },
  data: () => ({
    id: null
  }),
  created () {
    this.id = this.$route.params.id
  },
  computed: {
    visibleProjectBtn: function () {
      return this.$route.name !== 'ProjectView'
    },
    visibleEditBtn: function () {
      // TODO вынести в настройки на бэке, таблица bpm_properties
      return (this.$route.name === 'ProjectView' &&
        (this.$auth.user().id === this.executor_id ||
          (
            ['draft', 'initiation'].indexOf(this.status) !== -1 &&
            [this.executor_id, this.creator_id, this.whom_id, this.customer_id].indexOf(this.$auth.user().id) > -1
          )
        ) &&
        this.$store.state.project.visibleButton.edit)
    },
    visibleControlPoint: function () {
      return this.$store.state.project.visibleButton.cp && this.$route.name !== 'ControlPoint'
    },
    visiblePlanBtn: function () {
      return this.$store.state.project.visibleButton.plan && this.$route.name !== 'ProjectPlan'
    },
    visibleFinalReportBtn: function () {
      return this.$store.state.project.visibleButton.finalReport && this.$route.name !== 'ProjectFinal'
    }
  }
}
</script>

<style scoped>

</style>
