<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-briefcase"
      inline
      class="px-5 py-3"
      title="Паспорт проекта"
    >
      <v-progress-linear
          v-if="$store.state.bpm.timeline"
          v-model="timeline"
          color="pink"
      ></v-progress-linear>
      <v-row>
        <v-col cols="12" lg="7">
          <project-navigation
            :executor_id="fields.executor.id"
            :creator_id="fields.creator_id"
            :customer_id="fields.customer.id"
            :whom_id="fields.whom_id"
            :status="fields.status"/>
        </v-col>
        <v-col cols="12" lg="3">
          <div v-for="(value, name, idx) in fields.appraisals.passport" :key="idx" class="d-flex justify-start">
            <span class="text-body-2 pt-1" style="width: 40%">{{ name|mapRating }}</span>
            <v-rating :value="value" readonly dense></v-rating>
          </div>
        </v-col>
        <v-col cols="12" lg="2">
          <div><span class="subtitle-2">Статус: </span><span class="body-2 text-uppercase">{{ status.status || '' }}</span></div>
          <div><span class="subtitle-2">На ком: </span><span class="body-2">{{ status.whom || '' }}</span></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p>
            <span class="font-weight-black">Наименование проекта: </span>{{ fields.name }}
          </p>
          <p>
            <span class="font-weight-black">Долгосрочная цель: </span>{{ fields.goal ? fields.goal.text : '' }}
          </p>
          <p>
            <span class="font-weight-black">Цель (вектор): </span>{{ fields.goal_vector }} ({{ fields.vector_value }})
          </p>
          <p class="d-flex flex-column">
            <span class="font-weight-black">Результат:</span>
            <span
              v-for="(item, idx) in fields.result"
              :key="idx"
            ><span class="font-weight-bold">{{ idx+1 }}.</span> {{ item.text }} ({{ item.value }})</span>
          </p>
          <p>Заказчик проекта: <span class="font-weight-bold">{{ fields.customer.full_name }}</span></p>
          <p>Руководитель проекта: <span class="font-weight-bold">{{ fields.executor.full_name }}</span></p>
          <p>
            <span>Участники проекта:</span>
            <v-simple-table>
              <thead>
              <tr>
                <th width="30%">
                  Участники
                </th>
                <th width="65%">
                  Функционал
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="(item, idx) in fields.members"
                :key="idx"
              >
                <td>
                  <span>{{ item.member ? item.member.full_name : '' }}</span>
                </td>
                <td>
                  <span>{{ item.functional || '' }}</span>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </p>
          <v-divider class="my-5" />
          <p>
            Стоимость проекта: <span class="font-weight-bold">{{ fields.cost }} тыс.руб.</span>
          </p>
          <p>
            Источник финансирования: <span class="font-weight-bold">{{ fields.finance_source }}</span>, статья - <span class="font-weight-bold">{{ fields.finance_article }}</span>
          </p>
          <p>
            Оценка эффекта (потенциал/ожидаемое) тыс.руб.: <span class="font-weight-bold">{{ fields.fin_potential }} / {{ fields.fin_expected }}</span>
          </p>
          <p>
            Срок проекта: <span class="font-weight-bold">
              {{ $moment(fields.deadline_dt, "YYYY-MM-DD").from($moment(fields.start_dt, "YYYY-MM-DD").subtract(1, 'days'), true) }}
            </span> ({{ $moment(fields.start_dt).format("L") }} - {{ $moment(fields.deadline_dt).format("L") }})
          </p>
          <v-divider class="my-3"></v-divider>
          <p>
            <span class="d-flex justify-space-between">
              <span>Контрольные точки проекта:</span>
              <span><v-btn color="primary" small outlined @click="toCalendar"><v-icon>mdi-calendar</v-icon>В календарь</v-btn></span>
            </span>
            <v-simple-table>
              <tbody>
              <tr
                v-for="(item, idx) in fields.project_cp"
                :key="idx"
              >
                <td width="10%">
                  <div class="d-flex justify-center">
                  № {{ idx+1 }}
                  </div>
                </td>
                <td width="90%">
                  <div class="d-flex justify-center">
                  {{ item.cp_dt }}
                  </div>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </p>
          <p>
            Категория проекта: <span class="font-weight-bold">{{ fields.category }}</span>
          </p>
          <p class="d-flex flex-column">
            <span class="font-weight-black">Нельзя допустить:</span>
            <span
              v-for="(item, idx) in fields.not_allowed"
              :key="idx"
            ><span class="font-weight-bold">{{ idx+1 }}.</span> {{ item.text }}</span>
          </p>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-attachment :is-closed="fields.is_closed" :id="id" type="project" />
      <v-divider class="mt-5 mb-3"></v-divider>
      <v-row>
        <v-btn color="primary" class="ma-2" small @click="openCommentDialog"><v-icon left>mdi-comment-text-outline</v-icon>Комментарий</v-btn>
        <v-spacer></v-spacer>
        <workflow-button
          :type="`project`"
          :object_id="id"
          @changeFields="changeFields"
        ></workflow-button>
      </v-row>
    </base-card>
    <v-view-tabs
      :type="`project`"
      :id="id"
      @onAddComment="addComment"
      view-history
      view-comments
    />

  </v-container>
</template>

<script>
import ProjectNavigation from '../../components/ProjectNavigation'
import VViewTabs from '../../components/VViewTabs'
import WorkflowButton from '../../components/workflow/WorkflowButton'
import { mixin as VueTimers } from 'vue-timers'
import VAttachment from '../../components/VAttachment'
export default {
  name: 'ProjectView',
  mixins: [VueTimers],
  timers: {
    timelineProgress: { time: 1000, repeat: true, autostart: true, immediate: true }
  },
  components: { VAttachment, WorkflowButton, VViewTabs, ProjectNavigation },
  data: () => ({
    id: 0,
    commentDialog: false,
    commentText: '',
    comments: [],
    workflow: undefined,
    workflowComment: false,
    workflowAction: -1,
    files: [],
    plan: null,
    validComment: true,
    cp_count: 0,
    fields: {
      status: '',
      status_name: '',
      name: '',
      goal: { id: null, text: '', value: '' },
      goal_vector: '',
      vector_value: null,
      result: [{ id: null, text: '', value: null }],
      creator_id: 0,
      customer: { full_name: '' },
      executor: { full_name: '' },
      members: [{ id: null, functional: '', user_id: null, project_id: null, member: { full_name: '' } }],
      cost: 0,
      finance_source: '',
      finance_article: '',
      fin_potential: null,
      fin_expected: null,
      start_dt: '',
      deadline_dt: '',
      category: '',
      not_allowed: [{ id: 0, text: '' }],
      whom: { short_name: '' },
      whom_id: null,
      appraisals: {},
      attachments: [],
      project_cp: [{ cp_dt: '' }],
      is_closed: false
    }
  }),
  created () {
    this.id = parseInt(this.$route.params.id)
  },
  mounted () {
    this.getItem()
  },
  computed: {
    appraisalSelect: function () {
      return this.fields.appraisal > 0
    },
    visibleRating: function () {
      let res = false
      if (this.workflow === undefined) {
        return false
      }
      this.workflow.next_data.forEach(function (item, i, arr) {
        if (item.appraisal !== undefined && item.appraisal) {
          res = true
        }
      })
      return res
    },
    status: function () {
      return this.$store.state.bpm.status
    },
    timeline: function () {
      return this.$store.state.bpm.timeline.start / this.$store.state.bpm.timeline.total * 100
    }
  },
  methods: {
    async getItem () {
      // this.items = await this.$store.dispatch('project/GET_ITEMS')
      const project = await this.$store.dispatch('project/GET_PROJECT_VIEW', this.id)
      this.fields = project.project
      this.plan = project.plan
      this.cp_count = project.cp
    },
    openCommentDialog () {
      this.commentText = ''
      this.$store.commit('comments/OPEN_COMMENT_DIALOG')
    },
    addComment () {
      // if (this.workflowComment) {
      //   this.workflow.next_data[this.workflowAction].comment = false
      //   this.processWorkflow(this.workflowAction)
      // }
      if (this.$store.state.bpm.workflowComment) {
        const action = this.$store.state.bpm.workflowAction
        this.$store.commit('bpm/SET_COMMENT_ALREADY_ADDED', action)
        this.$store.commit('bpm/RESET_COMMENT_STATE')
        this.$store.dispatch('bpm/processWorkflow', action)
      }
    },
    changeFields (value) {
      const _ = this
      value.forEach(function (item, i, arr) {
        _.fields[item.field] = item.val
      })
    },
    timelineProgress () {
      const timeline = this.$store.state.bpm.timeline
      if (!timeline) return
      if (timeline.start >= timeline.total) return
      this.$store.commit('bpm/SET_TIMELINE', { ...this.$store.state.bpm.timeline, start: timeline.start + 1 })
    },
    toCalendar () {
      this.$store.dispatch('project/TO_CALENDAR', this.id)
        .then(() => {
          this.flashMessage.success({ title: 'Календарь', message: 'Контрольные точки отправлены в календарь.' })
        })
    }
  },
  filters: {
    mapRating (pure) {
      const map = new Map([
        ['mean', 'средняя'],
        ['quality', 'качества'],
        ['term', 'срока'],
        ['budget', 'бюджета'],
        ['main', 'общая']
      ])
      return `Оценка ${map.get(pure)}:`
    }
  }
}
</script>

<style scoped>

</style>
