<template>
  <v-container>
    <v-form
        ref="project_form"
        v-model="valid"
        lazy-validation
    >
      <v-row>
        <v-col cols="12">
          <v-text-field
              v-model="fields.name"
              label="Наименование проекта"
              :rules="rules.required"
          />
          <v-select
              v-model="fields.goal_id"
              :items="items_long_goal"
              item-value="id"
              label="Долгосрочная цель"
              :rules="[v => requiredNonDraft(v)]"
          />
          <v-row class="ma-0">
            <v-col cols="10" class="ma-0 pl-0">
              <v-text-field
                  v-model="fields.goal_vector"
                  label="Цель (вектор)"
                  :rules="[v => requiredNonDraft(v)]"
              />
            </v-col>
            <v-col cols="2" class="ma-0 pr-0">
              <v-number
                  v-model.number="fields.vector_value"
                  label="Числовое значение"
                  negative
                  :rules="[v => requiredNonDraft(v)]"
              />
            </v-col>
          </v-row>
          <v-row
              v-for="i in result_count"
              :key="i"
              class="ma-0"
          >
            <v-col cols="10" class="ma-0 pl-0 py-0">
              <v-text-field
                  v-model="fields.result[i-1].text"
                  label="Результат"
                  :rules="[v => requiredNonDraft(v)]"
              >
                <template v-slot:prepend>{{ `${i}.` }}</template>
              </v-text-field>
            </v-col>
            <v-col cols="2" class="ma-0 pr-0 py-0">
              <v-number
                  v-model.number="fields.result[i-1].value"
                  label="Значение результата"
                  negative
                  :rules="[v => requiredNonDraft(v)]"
              >
                <template v-slot:append-outer>
                  <v-btn
                      icon
                      color="primary"
                      @click="add_result"
                  ><v-icon>mdi-plus-thick</v-icon></v-btn>
                  <v-btn
                      icon
                      color="error"
                      @click="remove_result(i-1)"
                  ><v-icon>mdi-minus</v-icon></v-btn>
                </template>
              </v-number>
            </v-col>
          </v-row>
          <v-combo-multisearch
              v-model="fields.customer_id"
              :items="items_users"
              item-value="id"
              item-text="full_name"
              item-desc="position"
              label="Заказчик"
              :rules="rules.required"
              avatar
          />
          <v-combo-multisearch
              v-model="fields.executor_id"
              :items="items_users"
              item-value="id"
              item-text="full_name"
              item-desc="position"
              label="Руководитель"
              :rules="rules.required"
              avatar
          />

          <v-text-field
              v-model.number="fields.cost"
              label="Стоимость проекта (тыс. руб.)"
              :rules="[v => (v===0 || !!v || requiredNonDraft(v)) || 'Обязательное поле']"
          >
          </v-text-field>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-if="visibleFinance"
                  v-model="fields.finance_source"
                  label="Источник финансирования (наименование юр.лица)"
                  :rules="[v => requiredNonDraft(v)]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-if="visibleFinance"
                  v-model="fields.finance_article"
                  label="Статья"
                  :rules="[v => requiredNonDraft(v)]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="2" class="d-flex align-center"><span class="text-subtitle-1 font-weight-bold text--secondary">Оценка годового эффекта:</span></v-col>
            <v-col cols="6" lg="5">
              <v-text-field
                  v-model="fields.fin_potential"
                  label="Потенциал (в тыс. руб.)"
                  :rules="[v => requiredNonDraft(v)]"
              />
            </v-col>
            <v-col cols="6" lg="5">
              <v-text-field
                  v-model="fields.fin_expected"
                  label="Ожидаемое (в тыс. руб.)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <date-picker v-model="fields.start_dt" label="Начать" :rules="[v => requiredNonDraft(v)]" :min="$moment().add(15, 'day').format('YYYY-MM-DD')"/>
            </v-col>
            <v-col cols="6">
              <date-picker v-model="fields.deadline_dt" label="Завершить" :min="fields.start_dt" :rules="[v => requiredNonDraft(v)]"/>
            </v-col>
          </v-row>
          <v-select
              v-model="fields.category"
              :items="items_category"
              item-value="code"
              item-text="name"
              label="Категория"
              :rules="rules.required"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
              v-for="i in notallowed_count"
              :key="i"
              v-model="fields.not_allowed[i-1].text"
              label="Нельзя допустить"
              :rules="[v => requiredNonDraft(v)]"
          >
            <template v-slot:prepend>{{ `${i}.` }}</template>
            <template v-slot:append-outer>
              <v-btn
                  icon
                  color="primary"
                  @click="add_notallowed"
              ><v-icon>mdi-plus-thick</v-icon></v-btn>
              <v-btn
                  icon
                  color="error"
                  @click="remove_notallowed(i-1)"
              ><v-icon>mdi-minus</v-icon></v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-2" />
      <v-simple-table>
        <thead>
        <tr>
          <th></th>
          <th width="30%">Участники</th>
          <th width="65%">Функционал</th>
          <th class="text-right" />
        </tr>
        </thead>
        <tbody>
        <tr v-for="i in member_count" :key="i">
          <td>{{ `${i}.` }}</td>
          <td>
            <v-combo-multisearch
                v-model="fields.members[i-1].user_id"
                :items="items_users"
                item-value="id"
                item-text="full_name"
                item-desc="position"
                label="Участник"
                avatar
                :rules="[v => requiredNonDraft(v)]"
            />
          </td>
          <td>
            <v-text-field v-model="fields.members[i-1].functional" :rules="[v => requiredNonDraft(v)]"/>
          </td>
          <td>
            <v-btn icon color="error" @click="remove_member(i-1)"><v-icon>mdi-minus</v-icon></v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-btn icon color="primary" @click="add_member"><v-icon>mdi-plus-thick</v-icon></v-btn>
      <v-divider class="my-6" />
      <v-row>
        <v-col cols="12">
          <v-btn color="primary" class="ma-2" @click="saveData" :disabled="!valid">Сохранить</v-btn>
          <v-btn color="warning" class="ma-2" @click="$router.go(-1)">Отменить</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import VComboMultisearch from '../VComboMultisearch'
import VNumber from '../VNumber'
// import createNumberMask from 'text-mask-addons/dist/createNumberMask'
// const numberMask = createNumberMask({
//   prefix: '',
//   suffix: '',
//   allowDecimal: true,
//   includeThousandsSeparator: true,
//   allowNegative: true
// })
// const integerMask = createNumberMask({
//   prefix: '',
//   suffix: '',
//   allowDecimal: false,
//   includeThousandsSeparator: true,
//   allowNegative: false
// })
export default {
  name: 'ProjectEditForm',
  components: {
    VNumber,
    VComboMultisearch
  },
  props: {
    fields: {
      type: Object,
      default: function () {
        return {
          name: '',
          customer_id: null,
          manager_id: null,
          goal_id: null,
          goal_vector: '',
          vector_value: null,
          members: [
            { user_id: null, functional: '' }
          ],
          cost: null,
          deadline_dt: '',
          start_dt: '',
          status: 'draft',
          category: null,
          result: [
            { text: '', value: null }
          ],
          not_allowed: [
            { text: '' }
          ],
          finance_source: '',
          finance_article: '',
          fin_potential: null,
          fin_expected: null
        }
      }
    }
  },
  data: () => ({
    valid: true,
    // nMask: numberMask,
    // iMask: integerMask,
    vectorValue: null,
    costValue: null,
    resultValue: [],
    rules: {
      required: [v => !!v || 'Обязательное поле']
      // requiredNonDraft: [function (v) { return reqNonDraft(v) }]
    }
  }),
  created () {
    this.getItems()
    this.fields.customer_id = this.$auth.user().id
  },
  mounted () {
  },
  computed: {
    result_count: function () {
      return (this.fields.result === undefined) ? 0 : this.fields.result.length
    },
    member_count: function () {
      return (this.fields.members === undefined) ? 0 : this.fields.members.length
    },
    notallowed_count: function () {
      return (this.fields.not_allowed === undefined) ? 0 : this.fields.not_allowed.length
    },
    items_status: function () {
      return this.$store.state.project.items.status
    },
    items_users: function () {
      return this.$store.state.project.items.users
    },
    items_long_goal: function () {
      return this.$store.state.project.items.long_goal
    },
    items_category: function () {
      return this.$store.state.project.items.category
    },
    visibleFinance: function () {
      return !!this.fields.cost
    }
  },
  methods: {
    getItems () {
      this.$store.dispatch('project/GET_ITEMS')
    },
    saveData () {
      this.$emit('onProjectSave', this.$refs.project_form)
    },
    add_member () {
      this.fields.members.push({ functional: '', user_id: null })
    },
    remove_member (val) {
      if (this.member_count > 1) this.fields.members.splice(val, 1)
    },
    add_result () {
      this.fields.result.push({ text: '', value: null })
    },
    remove_result (val) {
      if (this.result_count > 1) this.fields.result.splice(val, 1)
    },
    add_notallowed () {
      this.fields.not_allowed.push({ text: '' })
    },
    remove_notallowed (val) {
      if (this.notallowed_count > 1) this.fields.not_allowed.splice(val, 1)
    },
    requiredNonDraft (v) {
      const res = Boolean(v) || this.fields.status === 'draft' || 'Обязательное поле'
      return res
    }
  }
}
</script>

<style scoped>

</style>
