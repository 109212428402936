<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-briefcase"
      inline
      class="px-5 py-3"
      title="Итоговый отчет о выполнении проекта"
    >
      <v-row>
        <v-col cols="12" lg="10">
          <project-navigation
            :executor_id="fields.executor.id"
            :creator_id="fields.creator_id"
            :customer_id="fields.customer.id"
            :whom_id="fields.whom_id"
            :status="fields.status"/>
        </v-col>
        <v-col cols="12" lg="2">
          <div v-if="fields.appraisal !== null" class="d-flex justify-start">
            <span class="subtitle-2">Оценка: </span>
            <v-rating class="ml-2" readonly v-model="fields.appraisal" dense></v-rating>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p>
            <span class="font-weight-black">Наименование проекта: </span>{{ fields.name }}
          </p>
          <p>
            <span class="font-weight-black">Долгосрочная цель: </span>{{ fields.goal.text }}
          </p>
          <p>
            <span class="font-weight-black">Цель (вектор): </span>{{ fields.goal_vector }} ({{ fields.vector_value }})
            <span class="font-weight-black ml-5">Результат: {{ fields.vector_fact }}</span>
          </p>

          <div class="d-flex flex-column mb-3">
            <span class="font-weight-black d-flex justify-start">Результат:</span>
            <div v-for="(item, idx) in fields.result" :key="idx" class="d-flex justify-start">
              <div>
                <span class="font-weight-bold">{{ idx+1 }}.</span> {{ item.text }} ({{ item.value }})
                <span class="font-weight-bold ml-5">Результат: {{ item.value_fact}}</span>
                <v-chip :color="item.performed?'success':'error'" outlined small class="ml-2">{{ item.performed?'ВЫПОЛНЕНО':'НЕ ВЫПОЛНЕНО' }}</v-chip>
              </div>
              <div
                v-if="!item.performed"
                v-html="item.comment"
                class="pl-5 text-body-2"
              ></div>
            </div>
          </div>
          <p>Заказчик проекта: <span class="font-weight-bold">{{ fields.customer.full_name }}</span></p>
          <p>Руководитель проекта: <span class="font-weight-bold">{{ fields.executor.full_name }}</span></p>
          <p>
            <span>Участники проекта:</span>
            <v-simple-table>
              <thead>
              <tr>
                <th width="25%">
                  Участники
                </th>
                <th width="65%">
                  Функционал
                </th>
                <th width="20%">
                  Оценки
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="(item, idx) in fields.members"
                :key="idx"
              >
                <td>
                  <span>{{ item.member.full_name }}</span>
                </td>
                <td>
                  <span>{{ item.functional }}</span>
                </td>
                <td>
                  <div v-for="(value, name, i) in item.appraisals" :key="i" class="d-flex justify-start">
                    <span class="text-body-2 pt-1">{{ name|mapRating }}</span>
                    <v-rating :value="value" dense readonly></v-rating>
                  </div>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </p>
          <v-divider class="my-5" />
          <span class="text-h6">Стоимость проекта:</span>
          <v-simple-table>
            <thead>
            <tr>
              <th width="34%">План (тыс.руб)</th>
              <th width="33%">Факт (тыс.руб)</th>
              <th width="33%">Отклонение (тыс.руб)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{ fields.cost }}</td>
              <td>{{ fields.cost_fact }}</td>
              <td>{{ fields.cost - fields.cost_fact }}</td>
            </tr>
            </tbody>
          </v-simple-table>
          <p>
            Источник финансирования: <span class="font-weight-bold">{{ fields.finance_source }}</span>, статья - <span class="font-weight-bold">{{ fields.finance_article }}</span>
          </p>
          <p>
            Оценка эффекта (потенциал/ожидаемое) тыс.руб.: <span class="font-weight-bold">{{ fields.fin_potential }} / {{ fields.fin_expected }}</span>
          </p>
          <p>
            Срок проекта: <span class="font-weight-bold">
              {{ $moment(fields.deadline_dt, "YYYY-MM-DD").from($moment(fields.start_dt, "YYYY-MM-DD").subtract(1, 'days'), true) }}
            </span> ({{ $moment(fields.start_dt).format("L") }} - {{ $moment(fields.deadline_dt).format("L") }})
          </p>
          <p>
            <span>Контрольные точки проекта:</span>
            <v-simple-table>
              <thead>
              <tr>
                <th>Этапы</th>
                <th>Дата</th>
                <th>Оценка качества</th>
                <th>Оценка срока</th>
                <th>Оценка бюджета</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td><div class="d-flex justify-start">Паспорт проекта</div></td>
                <td><div class="d-flex justify-start"></div></td>
                <td><div class="d-flex justify-start"><v-rating :value="getAppraisalPassportVal('quality')" readonly dense/></div></td>
                <td><div class="d-flex justify-start"><v-rating :value="getAppraisalPassportVal('term')" readonly dense/></div></td>
                <td><div class="d-flex justify-start"></div></td>
              </tr>
              <tr>
                <td><div class="d-flex justify-start">План проекта</div></td>
                <td><div class="d-flex justify-start"></div></td>
                <td><div class="d-flex justify-start"><v-rating :value="getAppraisalPlanVal('quality')" readonly dense/></div></td>
                <td><div class="d-flex justify-start"><v-rating :value="getAppraisalPlanVal('term')" readonly dense/></div></td>
                <td><div class="d-flex justify-start"></div></td>
              </tr>
              <tr
                v-for="(item, idx) in fields.project_cp"
                :key="idx"
              >
                <td><div class="d-flex justify-start">Контрольная точка № {{ idx+1 }}</div></td>
                <td><div class="d-flex justify-start">{{ item.cp_dt }}</div></td>
                <td><div class="d-flex justify-start"><v-rating :value="item.appraisal_quality" readonly dense/></div></td>
                <td><div class="d-flex justify-start"><v-rating :value="item.appraisal_term" readonly dense/></div></td>
                <td><div class="d-flex justify-start"><v-rating :value="item.appraisal_budget" readonly dense/></div></td>
              </tr>
              <tr style="background-color: tomato">
                <td colspan="2"><div class="d-flex justify-end font-weight-black">Оценки заказчика за проект:</div></td>
                <td><div class="d-flex justify-start"><v-rating :value="fields.appraisals.project.quality" readonly dense/></div></td>
                <td><div class="d-flex justify-start"><v-rating :value="fields.appraisals.project.term" readonly dense/></div></td>
                <td><div class="d-flex justify-start"><v-rating :value="fields.appraisals.project.budget" readonly dense/></div></td>
              </tr>
              </tbody>
            </v-simple-table>
          </p>
          <p>
            Категория проекта: <span class="font-weight-bold">{{ fields.category }}</span>
          </p>
          <div class="d-flex flex-column mb-3">
            <span class="font-weight-black">Нельзя допустить:</span>
            <div v-for="(item, idx) in fields.not_allowed" :key="idx" class="d-flex justify-start">
              <div>
                <span class="font-weight-bold">{{ idx+1 }}.</span> {{ item.text }}
                <v-chip :color="item.performed?'success':'error'" outlined small class="ml-2">{{ item.performed?'ВЫПОЛНЕНО':'НЕ ВЫПОЛНЕНО' }}</v-chip>
              </div>
              <div
                v-if="!item.performed"
                v-html="item.comment"
                class="pl-5 text-body-2"
              ></div>
            </div>
          </div>
        </v-col>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
import ProjectNavigation from '../../components/ProjectNavigation'
export default {
  name: 'ProjectView',
  components: { ProjectNavigation },
  data: () => ({
    id: 0,
    plan: null,
    cp_count: 0,
    fields: {
      status: '',
      status_name: '',
      name: '',
      goal: { id: null, text: '', value: '' },
      goal_vector: '',
      vector_value: null,
      vector_fact: null,
      result: [{ id: null, text: '', value: null }],
      creator_id: 0,
      customer: { full_name: '' },
      executor: { full_name: '' },
      members: [{ id: null, functional: '', user_id: null, project_id: null, member: { full_name: '' } }],
      cost: 0,
      finance_source: '',
      finance_article: '',
      fin_potential: null,
      fin_expected: null,
      start_dt: '',
      deadline_dt: '',
      category: '',
      not_allowed: [{ id: 0, text: '' }],
      whom: { short_name: '' },
      whom_id: null,
      appraisals: 0,
      attachments: [],
      project_cp: [{ cp_dt: '' }]
    }
  }),
  created () {
    this.id = parseInt(this.$route.params.id)
  },
  mounted () {
    this.getItem()
  },
  computed: {
    appraisalSelect: function () {
      return this.fields.appraisal > 0
    },
    visibleRating: function () {
      let res = false
      if (this.workflow === undefined) {
        return false
      }
      this.workflow.next_data.forEach(function (item, i, arr) {
        if (item.appraisal !== undefined && item.appraisal) {
          res = true
        }
      })
      return res
    },
    status: function () {
      return this.$store.state.bpm.status
    }
  },
  methods: {
    async getItem () {
      const project = await this.$store.dispatch('project/GET_PROJECT_VIEW', this.id)
      this.fields = project.project
      this.plan = project.plan
      this.cp_count = project.cp
    },
    getAppraisalPassportVal (type) {
      if (!this.fields.appraisals.passport || !this.fields.appraisals.passport[type]) return 0
      return this.fields.appraisals.passport[type]
    },
    getAppraisalPlanVal (type) {
      if (!this.plan || !this.plan.appraisals[type]) return 0
      return this.plan.appraisals[type]
    }
  },
  filters: {
    mapRating (pure) {
      const map = new Map([
        ['mean', 'Средняя'],
        ['quality', 'Качество'],
        ['term', 'Срок'],
        ['budget', 'Бюджет'],
        ['main', 'Общая']
      ])
      return `${map.get(pure)}:`
    }
  }
}
</script>

<style scoped>

</style>
