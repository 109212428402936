<template>
  <base-card
    color="indigo"
    icon="mdi-briefcase"
    inline
    class="px-5 py-3 mt-5"
    title="Финальный отчет"
  >
    <v-form
      ref="form"
      v-model="validReport"
      lazy-validation
    >
      <p class="d-flex justify-start">
        <span class="mt-2 mr-6">
          <span class="font-weight-black">Цель (вектор): </span>{{ project.goal_vector }} ({{ project.vector_value }}):
        </span>
        <v-text-field
          v-model="project.vector_fact"
          type="number"
          dense
          style="max-width: 200px"
          :rules="[v => (!!v || v === 0) || 'Обязательное поле']"
        ></v-text-field>
      </p>
      <div class="d-flex flex-column">
        <span class="font-weight-black">Результат:</span>
        <div
          v-for="(item, idx) in project.result"
          :key="idx"
          class="d-flex flex-column mb-3"
        >
          <div class="d-flex justify-start">
            <span class="mt-2 mr-6">
              <span class="font-weight-bold">{{ idx+1 }}.</span> {{ item.text }} ({{ item.value }}):
            </span>
            <v-text-field
              v-model="item.value_fact"
              type="number"
              dense
              class="mr-6"
              style="max-width: 200px"
              :rules="[v => !!v || 'Обязательное поле']"
            ></v-text-field>
            <v-select
              v-model="item.performed"
              :items="result"
              dense
              clearable
              style="max-width: 200px"
              :rules="[v => (!!v || v === false) || 'Обязательное поле']"
            ></v-select>
          </div>
          <tiptap-vuetify
            v-if="item.performed === false"
            style="width: 100%"
            v-model="item.comment"
            :extensions="extensions"
            :rules="[v => !!v || 'Обязательное поле']"
          ></tiptap-vuetify>
        </div>
      </div>
      <v-simple-table>
        <thead>
        <tr>
          <th></th>
          <th width="25%">Участники</th>
          <th width="65%">Функционал</th>
          <th width="20%">Оценки</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, idx) in project.members" :key="idx">
          <td>{{ `${idx+1}.` }}</td>
          <td>{{ item.member.full_name }}</td>
          <td>{{ item.functional }}</td>
          <td>
            <div v-for="(value, name, i) in item.appraisals" :key="i" class="d-flex justify-start">
              <span class="text-body-2 pt-1">{{ name|mapRating }}</span>
              <v-rating :value="value" @input="val => setAppraisal({ idx, name, val })" dense></v-rating>
            </div>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-divider class="my-5"></v-divider>
      <span class="text-h6">Стоимость проекта:</span>
      <v-simple-table>
        <thead>
        <tr>
          <th width="34%">План (тыс.руб)</th>
          <th width="33%">Факт (тыс.руб)</th>
          <th width="33%">Отклонение (тыс.руб)</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{ project.cost }}</td>
          <td>
            <v-text-field
              v-model="project.cost_fact"
              type="number"
              :rules="[v => (v===0 || !!v) || 'Обязательное поле']"
            ></v-text-field>
          </td>
          <td>{{ deviation }}</td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-divider class="my-5"></v-divider>

      <div class="d-flex flex-column">
        <span class="font-weight-black">Нельзя допустить:</span>
        <div
          v-for="(item, idx) in project.not_allowed"
          :key="idx"
          class="d-flex flex-column mb-3"
        >
          <div class="d-flex justify-start">
            <span class="mt-2 mr-6">
              <span class="font-weight-bold">{{ idx+1 }}.</span> {{ item.text }}
            </span>
            <v-select
              v-model="item.performed"
              :items="result"
              dense
              clearable
              style="max-width: 200px"
              :rules="[v => (!!v || v === false) || 'Обязательное поле']"
            ></v-select>
          </div>
          <tiptap-vuetify
            v-if="item.performed === false"
            style="width: 100%"
            v-model="item.comment"
            :extensions="extensions"
            :rules="[v => !!v || 'Обязательное поле']"
          ></tiptap-vuetify>
        </div>
      </div>
      <v-divider class="my-5"></v-divider>
      <span class="text-h6 mb-3">Вывод к проекту:</span>
      <div class="d-flex flex-column">
        <tiptap-vuetify
          style="width: 100%"
          v-model="project.conclusion"
          :extensions="extensions"
          :rules="[v => !!v || 'Обязательное поле']"
        ></tiptap-vuetify>
      </div>
      <v-divider class="mt-3"></v-divider>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-spacer></v-spacer>
          <v-btn
            v-if="!finalReportBlock"
            color="primary" class="ma-2" @click="saveReport(4)" :disabled="validateReport || !validReport" outlined small>Сохранить</v-btn>
          <v-btn
            v-if="visibleSendBtn"
            color="success" class="ma-2" @click="saveReport(5)" :disabled="validateReport || !validReport" outlined small>Отправить</v-btn>
          </v-col>
      </v-row>
    </v-form>
  </base-card>
</template>

<script>
import { TiptapVuetify, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, /* Heading, Link, */ Blockquote, HardBreak, /* HorizontalRule, */ History } from 'tiptap-vuetify'
export default {
  name: 'ProjectFinalReport',
  components: { TiptapVuetify },
  props: {
    project_cp: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data: () => ({
    project_id: null,
    project: {},
    validReport: true,
    extensions: [
      History,
      Blockquote,
      // Link,
      Bold,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      // [Heading, {
      //   options: {
      //     levels: [2, 3]
      //   }
      // }],
      Code,
      // HorizontalRule,
      Paragraph,
      HardBreak
    ],
    result: [
      { value: true, text: 'ВЫПОЛНЕНО' },
      { value: false, text: 'НЕ ВЫПОЛНЕНО' }
    ]
  }),
  created () {
    this.project_id = parseInt(this.$route.params.id)
    this.getData()
  },
  mounted () {
  },
  computed: {
    member_count: function () {
      return (this.members === undefined) ? 0 : this.members.length
    },
    deviation: function () {
      return this.project.cost - this.project.cost_fact
    },
    validateReport: function () {
      let res = false
      if (!this.project.members) return res
      this.project.members.forEach(function (item, i, array) {
        const arr = Object.entries(item.appraisals)
        arr.forEach(function (appraisal, idx) {
          if (appraisal[1] === 0) {
            res = true
            return res
          }
        })
        return res
      })
      if (!this.project.conclusion) res = true
      return res
    },
    visibleSendBtn: function () {
      return this.project_cp[this.project_cp.length - 1].state === 5
    },
    finalReportBlock: function () {
      if (!this.project_cp) return false
      return this.project_cp[this.project_cp.length - 1].state === 255
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('project/GET_FINAL_REPORTS_DATA', this.project_id)
        .then(response => {
          this.project = response.project
          this.project.members.forEach(function (item, i, array) {
            if (Object.keys(item.appraisals).length === 0) {
              array[i].appraisals = { main: 0 }
            }
          })
        })
    },
    setAppraisal (payload) {
      this.project.members[payload.idx].appraisals = { ...this.project.members[payload.idx].appraisals, [payload.name]: payload.val }
    },
    saveReport (state) {
      if (!this.$refs.form.validate()) {
        return
      }
      const id = this.project_id
      const stage = this.project_cp[this.project_cp.length - 1]
      const payload = {
        id: stage.id,
        position: stage.position,
        project: this.project,
        state: state,
        appraisal_term: null,
        appraisal_budget: null,
        appraisal_quality: null
      }
      payload.project.members.forEach(function (item, i, array) {
        delete item.member.full_name
        delete item.member.short_name
      })
      this.$store.dispatch('project/POST_CONTROL_POINT', { id, payload })
        .then(response => {
          // this.project_cp = response.cp
          this.$emit('updateCP', response.cp)
          switch (state) {
            case 4:
              this.flashMessage.success({ title: 'Финальный отчет', message: 'Данные сохранены' })
              break
            case 5:
              this.flashMessage.success({ title: 'Финальный отчет', message: 'Отчет сформирован' })
          }
        })
    }
  },
  filters: {
    mapRating (pure) {
      const map = new Map([
        ['mean', 'Средняя'],
        ['quality', 'Качество'],
        ['term', 'Срок'],
        ['budget', 'Бюджет'],
        ['main', 'Общая']
      ])
      return `${map.get(pure)}:`
    }
  }
}
</script>

<style scoped>

</style>
