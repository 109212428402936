<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Редактирование проекта"
    >
      <v-row>
        <v-col cols="8" />
        <v-col cols="4">
          <v-select
            v-model="fields.status"
            :items="items_status"
            item-value="code"
            item-text="name"
            label="Статус"
            readonly
            dense
          />
        </v-col>
      </v-row>

      <project-edit-form :fields="fields" @onProjectSave="saveData"/>
    </base-card>
  </v-container>
</template>

<script>
import ProjectEditForm from '../../components/form/ProjectEditForm'
import { removeEmptyFieldsWithDraft } from '../../function'
export default {
  name: 'ProjectCreate',
  components: { ProjectEditForm },
  data: () => ({
    fields: {},
    project_id: null
  }),
  created () {
    this.project_id = this.$route.params.id
    this.getItems()
  },
  computed: {
    items_status: function () {
      return this.$store.state.project.items.status
    }
  },
  methods: {
    async getItems () {
      const project = await this.$store.dispatch('project/GET_PROJECT', this.project_id)
      delete project.project_cp
      this.fields = project.project
      if (this.fields.members.length === 0) this.fields.members.push({ functional: '', user_id: null })
      if (this.fields.result.length === 0) this.fields.result.push({ text: '', value: null })
      if (this.fields.not_allowed.length === 0) this.fields.not_allowed.push({ text: '' })
    },
    async saveData (val) {
      if (!val.validate()) {
        return
      }
      this.$store.commit('OVERLAY_ON')
      if (this.fields.status === 'draft') {
        this.fields = removeEmptyFieldsWithDraft(this.fields)
      }
      const id = this.project_id
      const payload = {}
      Object.assign(payload, this.fields)
      await this.$store.dispatch('project/PUT_PROJECT', { id, payload })
        .then(response => {
          this.flashMessage.success({ title: 'Сохранение', message: 'Проект успешно сохранен' })
          this.$router.push({ path: `/projects/${this.project_id}/view` })
        })
        .catch(error => {
          this.btn_disabled = false
          const message = error.response.data.message || ''
          this.flashMessage.error({ title: 'Сохранение', message: `Ошибка сохранения проекта. ${message}` })
        }).finally(() => { this.$store.commit('OVERLAY_OFF') })
    },
    validateForm (scope) {
      this.btn_disabled = true
      this.saveData()
    }
  }
}
</script>

<style scoped>

</style>
