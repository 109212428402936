<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Отчет по контрольным точкам"
    >
      <v-row>
        <v-col class="d-flex justify-start">
          <project-navigation :user_id="plans.executor_id"/>
        </v-col>
      </v-row>
      <div
        class="pa-3 "
      >
        <!-- Сетка плана -->
        <div
          class="d-flex flex-row"
        >
          <div
            class="plan-month plan-cell plan-header"
          />
          <div
            class="plan-name plan-cell plan-header"
          >
            Мероприятия
          </div>
          <div
            class="plan-period plan-cell plan-header"
          >
            Срок
          </div>
          <!-- Колонка Факт выполнения -->
          <div
            v-for="col in point"
            :key="col"
            class="plan-fact-head"
          >
            <div class="d-flex plan-cell subtitle-2 justify-center">КТ {{ col }}, % выполнения</div>
            <div class="d-flex">
              <div class="d-flex plan-cell subtitle-2 justify-center" style="width: 50%">План</div>
              <div class="d-flex plan-cell subtitle-2 justify-center" style="width: 50%">Факт</div>
            </div>
          </div>
        </div>

        <div
          v-for="(row, idx1) in plan"
          :key="idx1"
          class="d-flex flex-column"
        >
          <div
            class="d-flex flex-row"
          >
            <!-- Вывод родительских веток -->
            <div class="plan-month plan-cell d-flex align-center justify-center plan-header plan-cell-main">
              {{ idx1+1 }}
            </div>
            <div class="plan-name plan-cell d-flex align-center plan-header plan-cell-main">
              {{ row.name }}
            </div>
            <div class="plan-period plan-cell plan-header plan-cell-main">
              {{ $moment(row.start_dt, 'YYYY-MM-DD').format('L') }} - <br>{{ $moment(row.end_dt, 'YYYY-MM-DD').format('L') }}
            </div>
            <!-- Колонка Факт выполнения -->
            <div
              v-for="(percent_plan, idx_pp) in row.percent"
              :key="idx_pp"
              class="plan-fact-head d-flex"
            >
              <div class="plan-fact plan-cell plan-cell-main d-flex justify-center align-center" style="width: 50%">{{ percent_plan.percent }}</div>
              <div class="plan-fact plan-cell plan-cell-main d-flex justify-center align-center px-2" style="width: 50%">{{ percent_plan.percent_fact }}</div>
            </div>
          </div>

          <!-- Вывод дочерних веток -->
          <div
            v-for="(subRow, idxS1) in row.children"
            :key="idxS1"
            class="d-flex flex-row"
          >
            <div class="plan-month plan-cell d-flex align-center justify-center"/>
            <div class="plan-name plan-cell d-flex align-center">
              {{ subRow.name }}
            </div>
            <div class="plan-period plan-cell">
              {{ $moment(subRow.start_dt, 'YYYY-MM-DD').format('L') }} - <br>{{ $moment(subRow.end_dt, 'YYYY-MM-DD').format('L') }}
            </div>
            <!-- Колонка Факт выполнения -->
            <div
              v-for="(percent_fact, idx_pf) in subRow.percent"
              :key="idx_pf"
              class="plan-fact-head d-flex"
            >
              <div class="plan-fact plan-cell d-flex justify-center align-center" style="width: 50%">{{ percent_fact.percent }}</div>
              <div v-if="percent_fact.cp_dt==cp_dt && $auth.user().id==plans.executor_id && state !== 255" class="plan-fact plan-cell d-flex justify-center align-center px-2" style="width: 50%">
                <v-text-field
                  v-model="percent_fact.percent_fact"
                  dense
                  type="number"
                  @input="changeInput(idx1, idx_pf)"
                ></v-text-field>
              </div>
              <div v-else class="plan-fact plan-cell d-flex justify-center align-center" style="width: 50%">{{ percent_fact.percent_fact }}</div>
            </div>
          </div>
        </div>
      </div>
      <v-divider class="mt-3" />
      <v-row v-if="$auth.user().id==plans.executor_id && state !== 255">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="ma-2" @click="savePlan">Сохранить</v-btn>
        <v-btn color="warning" class="ma-2" @click="$router.go(-1)">Отменить</v-btn>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
import ProjectNavigation from '../../components/ProjectNavigation'
export default {
  name: 'CPReports',
  components: { ProjectNavigation },
  data: () => ({
    plan: [],
    plans: {},
    point: 0,
    state: 0,
    cp_dt: null,
    res: [],
    workflow: {},
    project_id: null
  }),
  created () {
    this.project_id = this.$route.params.id
  },
  mounted () {
    this.getPlan()
  },
  methods: {
    async getPlan () {
      const data = await this.$store.dispatch('project/GET_REPORTS', this.project_id)
      this.plan = data.plan_schedule
      this.plans = data.plans
      this.point = data.point
      this.cp_dt = data.cp_dt
      this.state = data.state
    },
    changeInput (idx1, idx2) {
      const sum = this.plan[idx1].children.reduce((sum, { percent }) => sum + parseInt(percent[idx2].percent_fact), 0)
      const cnt = this.plan[idx1].children.length
      this.plan[idx1].percent[idx2].percent_fact = Math.trunc(sum / cnt) || 0
    },
    savePlan () {
      const id = this.project_id
      const payload = this.plan
      this.$store.commit('OVERLAY_ON')
      this.$store.dispatch('project/POST_REPORTS', { id, payload })
        .then(response => {
          this.flashMessage.success({ title: 'Изменение', message: 'Процент выполнения сохранен успешно' })
          this.$router.push({ path: `/projects/${this.project_id}/cp` })
        }).finally(() => { this.$store.commit('OVERLAY_OFF') })
    }
  }
}
</script>

<style scoped>
  .plan-header {
    font-weight: 600;
  }
  .plan-cell {
    border: thin solid rgba(200, 200, 200, 0.6);
    /*padding: 5px;*/
  }
  .plan-cell-main {
    border-top: 2px solid rgb(20, 20, 20);
  }
  .plan-month {
    width: 50px;
    text-align: center;
  }
  .plan-name {
    width: 30%;
    text-align: left;
    padding-left: 20px;
  }
  .plan-period {
    width: 8%;
    text-align: left;
    padding-left: 20px;
  }
  .plan-month-background {
    background-color: rgb(102, 255, 102);
  }
  .plan-month-background-light {
    background-color: rgb(204, 255, 204);
  }
  .plan-fact {
    width: 5%;
  }
  .plan-fact-head {
    width: 10%;
  }
  .plan-edit-background {
    background-color: #e6e2e2;
  }
</style>
