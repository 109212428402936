<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="План проекта"
    >
      <v-progress-linear
          v-if="$store.state.bpm.timeline"
          v-model="timeline"
          color="pink"
      ></v-progress-linear>
      <v-row>
        <v-col class="d-flex justify-start" cols="12" lg="7">
          <project-navigation :executor_id="plans.executor_id"/>
          <v-switch
            v-if="visibleEditSwitch"
            v-model="editMode"
            label="Редактировать"
            dense
            class="ml-5 mt-0"
          />
          <v-switch
            v-model="showAllTask"
            label="Показать все задачи"
            dense
            class="ml-5 mt-0"
          ></v-switch>
        </v-col>
        <v-col cols="12" lg="3">
          <div v-for="(value, name, idx) in plans.appraisals" :key="idx" class="d-flex justify-start">
            <span class="text-body-2 pt-1" style="width: 40%">{{ name|mapRating }}</span>
            <v-rating :value="value" readonly dense></v-rating>
          </div>
        </v-col>
        <v-col cols="12" lg="2">
          <div><span class="subtitle-2">Статус: </span><span class="body-2 text-uppercase">{{ status.status || '' }}</span></div>
          <div><span class="subtitle-2">На ком: </span><span class="body-2">{{ status.whom || '' }}</span></div>
        </v-col>
      </v-row>

      <v-card
        flat
        class="pa-3 d-flex flex-row overflow-x-auto"
        width="100vw"
      >
        <v-card
          flat
          :min-width="`${calcWidth()}`"
        >
          <!-- Сетка плана -->
          <!-- TODO вынести в отдельный модуль -->
          <div
            class="d-flex flex-row"
          >
            <div
              class="plan-month plan-cell plan-header"
            />
            <div
              class="plan-name plan-cell plan-header"
            >
              Мероприятия
            </div>
            <div
              class="plan-period plan-cell plan-header"
            >
              Срок
            </div>
            <div
              v-for="(item, idx) in plan.months"
              :key="idx"
              class="plan-month plan-cell subtitle-2"
            >
              {{ getStringMonth(item) }}
            </div>
            <!-- Колонка Факт выполнения -->
            <div v-if="visibleFact" class="plan-fact plan-cell subtitle-2">Факт выполнения</div>
          </div>
          <div
            v-for="(row, idx1) in plan.tasks"
            :key="idx1"
            class="d-flex flex-column"
          >
            <div
              class="d-flex flex-row"
            >
              <!-- Вывод родительских веток -->
              <div
                v-if="row.editMarker===undefined"
                class="plan-month plan-cell d-flex align-center justify-center plan-header plan-cell-main"
              >
                {{ idx1+1 }}
              </div>
              <div
                v-else
                class="plan-month plan-cell d-flex align-center justify-center plan-cell-main"
                :class="{'plan-edit-background': row.editMarker!==undefined}"
              >
                <v-icon @click="newItem()">
                  mdi-plus
                </v-icon>
              </div>
              <div
                class="plan-name plan-cell d-flex align-center plan-header plan-cell-main"
                :class="{
                  'plan-edit-background': row.editMarker!==undefined,
                  'justify-space-between': editMode
                }"
              >
                <span>{{ row.name }}</span>
                <span
                  v-if="editMode && row.editMarker===undefined"
                  class="d-flex justify-end"
                >
                  <v-icon @click="confirmDelete(row.id)">mdi-delete</v-icon>
                  <v-icon @click="editItem(row.id)">mdi-pencil</v-icon>
                </span>
              </div>
              <div
                class="plan-period plan-cell plan-header plan-cell-main"
                :class="{'plan-edit-background': row.editMarker!==undefined}"
              >
                <span v-if="row.editMarker===undefined">{{ $moment(row.start_dt, 'YYYY-MM-DD').format('L') }} -
                  <br>{{ $moment(row.end_dt, 'YYYY-MM-DD').format('L') }}</span>
              </div>
              <div
                v-for="(item, idx2) in row.month"
                :key="idx2"
                class="plan-month plan-cell plan-cell-main"
                :class="{'plan-month-background': item.item, 'plan-edit-background': row.editMarker!==undefined}"
              />
              <!-- Колонка Факт выполнения -->
              <div v-if="visibleFact" class="plan-fact plan-cell plan-cell-main d-flex justify-center align-center">{{ row.complete }}%</div>
            </div>
<!-- Вывод дочерних веток -->
            <div
              v-for="(subRow, idxS1) in row.children"
              :key="idxS1"
              class="d-block"
            >
              <div class="d-flex flex-row">
                <div
                  class="plan-month plan-cell d-flex align-center justify-center"
                />
                <div
                  v-if="subRow.editMarker===undefined"
                  class="plan-name plan-cell d-flex align-center"
                  :class="{
                    'plan-edit-background': subRow.editMarker!==undefined,
                    'justify-space-between': true //editMode
                  }"
                >
                  <span>{{ subRow.name }}</span>
                  <span v-if="editMode">
                    <v-btn icon>
                      <v-icon @click="confirmDelete(subRow.id)">mdi-delete</v-icon>
                    </v-btn>
                    <v-btn icon>
                      <v-icon @click="editItem(subRow.id)">mdi-pencil</v-icon>
                    </v-btn>
                  </span>
                  <span v-if="visibleAddTask">
                    <v-btn
                      icon
                      @click="addTask(subRow)"
                      v-tooltip.bottom="`Создать задачу`"
                    >
                      <v-icon>mdi-plus-thick</v-icon>
                    </v-btn>
                  </span>
                  <span v-if="subRow.tasks.length > 0">
                    <v-btn
                      icon
                      @click="subRow.visible_task = !subRow.visible_task"
                    >
                      <v-icon>mdi-menu-down-outline</v-icon>
                    </v-btn>
                  </span>
                </div>
                <div
                  v-else
                  class="plan-name plan-cell d-flex justify-end"
                  :class="{'plan-edit-background': subRow.editMarker!==undefined}"
                >
                  <v-icon @click="newItem(row.id)">
                    mdi-plus
                  </v-icon>
                </div>
                <div
                  class="plan-period plan-cell"
                  :class="{'plan-edit-background': subRow.editMarker!==undefined}"
                >
                  <span v-if="subRow.editMarker===undefined">{{ $moment(subRow.start_dt, 'YYYY-MM-DD').format('L') }} -
                    <br>{{ $moment(subRow.end_dt, 'YYYY-MM-DD').format('L') }}</span>
                </div>
                <div
                  v-for="(subItem, idxS2) in subRow.month"
                  :key="idxS2"
                  class="plan-month plan-cell"
                  :class="{'plan-month-background-light': subItem.item, 'plan-edit-background': subRow.editMarker!==undefined}"
                />
                <!-- Колонка Факт выполнения -->
                <div v-if="visibleFact" class="plan-fact plan-cell d-flex justify-center align-center">{{ subRow.complete }}%</div>
              </div>
<!-- Вывод задач -->
              <v-expand-transition>
                <div v-if="subRow.visible_task">
                  <div
                    v-for="(taskRow, idxT1) in subRow.tasks"
                    :key="idxT1"
                    class="d-flex flex-row"
                  >
                    <div class="plan-month plan-cell d-flex align-center justify-center" />
                    <div class="plan-name plan-cell d-flex align-center justify-space-between">
                      <router-link :to="`/tasks/${taskRow.id}/view`"><span>{{ taskRow.name }}</span></router-link>
                      <v-icon v-if="taskRow.is_closed" color="green">mdi-check</v-icon>
                    </div>
                    <div class="plan-period plan-cell"></div>
                    <div
                      v-for="(subItem, idxS2) in subRow.month"
                      :key="idxS2"
                      class="plan-month plan-cell"
                      :class="{'plan-month-background-light': subItem.item, 'plan-edit-background': subRow.editMarker!==undefined}"
                    />
                    <!-- Колонка Факт выполнения -->
                    <div v-if="visibleFact" class="plan-fact plan-cell d-flex justify-center align-center"></div>
                  </div>
                </div>
              </v-expand-transition>
<!-- Вывод задач -->
            </div>
          </div>
        </v-card>
      </v-card>
      <v-divider class="mt-5 mb-3" />
      <v-row>
        <v-btn color="primary" class="ma-2" small @click="openCommentDialog"><v-icon left>mdi-comment-text-outline</v-icon>Комментарий</v-btn>
        <v-spacer></v-spacer>
        <workflow-button
          :type="`plan`"
          :object_id="project_id"
          @changeFields="changeFields"
        ></workflow-button>
      </v-row>
    </base-card>
    <v-view-tabs
      :type="`plan`"
      :id="project_id "
      @onAddComment="addComment"
      view-history
      view-comments
    />
    <!--  Диалог добавления / редактирования  -->
    <!--  TODO вынести в отдельный модуль  -->
    <v-dialog
      v-model="editDialog"
      width="550px"
    >
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-form
            v-model="editDialogValidate"
            lazy-validation
            ref="planEditForm"
          >
            <v-text-field
              v-model="text"
              :rules="[v => !!v || 'Обязательное поле']"
            />
            <v-row v-show="showDateRow">
              <v-col cols="6">
                <date-picker v-model="startDt" label="Начало" @change="changeStartDate" />
              </v-col>
              <v-col cols="6">
                <date-picker v-model="endDt" label="Окончание" :min="startDt" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="closeDialog(false)"
          >
            Отмена
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :disabled="!editDialogValidate"
            @click="closeDialog(true)"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title class="headline">
          {{ confirmTitle }}
        </v-card-title>
        <v-card-text>{{ confirmText }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="confirmDialog=false"
          >
            Отмена
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="deleteItem"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ProjectNavigation from '../../components/ProjectNavigation'
import VViewTabs from '../../components/VViewTabs'
import WorkflowButton from '../../components/workflow/WorkflowButton'
import { mixin as VueTimers } from 'vue-timers'
export default {
  name: 'ProjectPlan',
  mixins: [VueTimers],
  timers: {
    timelineProgress: { time: 1000, repeat: true, autostart: true, immediate: true }
  },
  components: { ProjectNavigation, WorkflowButton, VViewTabs },
  data: () => ({
    item_id: null,
    parent_id: null,
    editDialog: false,
    editDialogValidate: true,
    dialogTitle: '',
    confirmDialog: false,
    confirmTitle: '',
    confirmText: '',
    text: '',
    startDt: new Date().toISOString().substr(0, 10),
    endDt: new Date().toISOString().substr(0, 10),
    project_id: null,
    plan: {
      // interval: 6,
      // months: ['2020-05', '2020-06', '2020-07', '2020-08', '2020-09', '2020-10', '2020-11', '2020-12'],
      // tasks: [
      //   {
      //     id: 1,
      //     name: 'Разработать продуктивный каталог по доп. услугам',
      //     start_dt: '2020-05-11',
      //     end_dt: '2020-05-29',
      //     month: [],
      //     children: [
      //       {
      //         name: 'проверить существующие договора',
      //         start_dt: '2020-05-11',
      //         end_dt: '2020-05-25',
      //         month: [],
      //       },
      //    },
      //  {
      //    name: 'Обеспечить продажу дополнительных услуг',
      //    start_dt: '2020-07-01',
      //    end_dt: '2020-12-21',
      //    month: [],
      //    children: [],
      //  },
      // ],
    },
    plans: {},
    // workflow: {},
    editMode: false,
    showDateRow: true,
    showAllTask: false
  }),
  async mounted () {
    await this.getPlan()
    if (this.plan.tasks.length === 0 && this.plans.executor_id === this.$auth.user().id) {
      this.editMode = true
    }
  },
  created () {
    this.project_id = parseInt(this.$route.params.id)
  },
  computed: {
    visibleFact: function () {
      return this.plans.status === 'work'
    },
    status: function () {
      return this.$store.state.bpm.status
    },
    visibleEditSwitch: function () {
      return this.$auth.user().id === this.plans.executor_id && ['draft', 'rework'].indexOf(this.$store.state.bpm.workflowState.obj.status) !== -1
    },
    visibleAddTask: function () {
      return this.$auth.user().id === this.plans.executor_id && ['plan', 'work'].indexOf(this.plans.project.status) !== -1
    },
    timeline: function () {
      return this.$store.state.bpm.timeline.start / this.$store.state.bpm.timeline.total * 100
    }
  },
  watch: {
    editMode (val) {
      if (val) {
        this.addEditMarker()
      } else {
        this.removeEditMarker()
      }
    },
    showAllTask (val) {
      this.plan.tasks.forEach(function (task, idx1, array1) {
        task.children.forEach(function (item, idx2, array2) {
          item.visible_task = val
        })
      })
    }
  },
  methods: {
    async getPlan () {
      const data = await this.$store.dispatch('project/GET_PLAN', this.project_id)
      this.plan = data.plan_schedule
      this.plans = data.plans
      this.buildPlan()
    },
    openCommentDialog () {
      this.$store.commit('comments/OPEN_COMMENT_DIALOG')
    },
    timelineProgress () {
      const timeline = this.$store.state.bpm.timeline
      if (!timeline) return
      if (timeline.start >= timeline.total) return
      this.$store.commit('bpm/SET_TIMELINE', { ...this.$store.state.bpm.timeline, start: timeline.start + 1 })
    },
    buildPlan () {
      const _ = this
      const month = this.plan.months
      const countMonth = this.plan.months.length
      this.plan.tasks.forEach(function (item, i, arr) {
        arr[i].month = _.fillMonth(item, countMonth, month)
        if (item.children.length > 0) {
          item.children.forEach(function (item, i, arr) {
            arr[i].month = _.fillMonth(item, countMonth, month)
          })
        }
      })
    },
    fillMonth (item, countMonth, planMonth) {
      const month = []
      for (let x = 0; x < countMonth; x++) {
        const ys = parseInt(this.$moment(item.start_dt, 'YYYY-MM-DD').format('Y'))
        const ye = parseInt(this.$moment(item.end_dt, 'YYYY-MM-DD').format('Y'))
        const y = parseInt(this.$moment(planMonth[x], 'YYYY-MM').format('Y'))
        const m = parseInt(this.$moment(planMonth[x], 'YYYY-MM').format('M')) + 12 * (y - ys)
        const ms = parseInt(this.$moment(item.start_dt, 'YYYY-MM-DD').format('M'))
        const me = parseInt(this.$moment(item.end_dt, 'YYYY-MM-DD').format('M')) + 12 * (ye - ys)
        if (m >= ms && m <= me) {
          month.push({ item: true })
        } else {
          month.push({ item: false })
        }
      }
      return month
    },
    getStringMonth (val) {
      return this.$moment(val, 'YYYY-MM').format('MMM')
    },
    addEditMarker () {
      const month = []
      for (let i = 0; i < this.plan.months.length; i++) {
        month.push({ item: false })
      }
      if (this.plan.tasks.length < 5) {
        this.plan.tasks.push(
          {
            name: '',
            start_dt: '',
            end_dt: '',
            month: month,
            editMarker: true,
            children: []
          }
        )
      }
      this.plan.tasks.forEach(function (item, i, arr) {
        if (item.children.length < 5 && item.editMarker === undefined) {
          arr[i].children.push(
            {
              name: '',
              start_dt: '',
              end_dt: '',
              month: month,
              editMarker: true
            }
          )
        }
      })
    },
    removeEditMarker () {
      const month = []
      for (let i = 0; i < this.plan.months.length; i++) {
        month.push({ item: false })
      }
      if (this.plan.tasks[this.plan.tasks.length - 1].editMarker !== undefined) {
        this.plan.tasks.pop()
      }
      this.plan.tasks.forEach(function (item, i, arr) {
        if (item.children[item.children.length - 1].editMarker !== undefined) {
          arr[i].children.pop()
        }
      })
    },
    async editItem (id) {
      this.item_id = id
      const data = await this.$store.dispatch('project/GET_PLAN_ITEM', id)
      const plan = data.plan
      if (plan.parent_id === null) {
        this.dialogTitle = 'Редактирование группы крупных задач'
        this.showDateRow = false
      } else {
        this.dialogTitle = 'Редактирование задачи'
        this.showDateRow = true
      }
      this.startDt = plan.start_dt
      this.endDt = plan.end_dt
      this.text = plan.name
      this.parent_id = plan.parent_id
      this.editDialog = true
    },
    newItem (id) {
      this.item_id = null
      this.parent_id = id
      if (id === null || id === undefined) {
        this.dialogTitle = 'Добавить группу крупных задач'
        this.showDateRow = false
      } else {
        this.dialogTitle = 'Добавить задачу'
        this.showDateRow = true
      }
      this.startDt = new Date().toISOString().substr(0, 10)
      this.endDt = new Date().toISOString().substr(0, 10)
      this.text = ''
      this.editDialog = true
    },
    async deleteItem () {
      const id = this.item_id
      const projectId = this.project_id
      const data = await this.$store.dispatch('project/DELETE_PLAN', { projectId, id })
      this.plan = data.plan
      this.buildPlan()
      if (this.editMode) {
        this.addEditMarker()
      }
      this.confirmDialog = false
    },
    confirmDelete (id) {
      this.item_id = id
      this.confirmTitle = 'Подтверждение удаления'
      this.confirmText = 'Уверены, что хотите удалить запись?'
      this.confirmDialog = true
    },
    async closeDialog (save) {
      if (save) {
        if (!this.$refs.planEditForm.validate()) {
          return
        }
        const payload = {
          project_id: this.project_id,
          start_dt: this.startDt,
          end_dt: this.endDt,
          name: this.text
        }
        if (this.item_id !== null) {
          payload.id = this.item_id
        }
        if (this.parent_id !== null) {
          payload.parent_id = this.parent_id
        }
        const data = await this.$store.dispatch('project/POST_PLAN', payload)
        this.plan = data.plan
        this.buildPlan()
        if (this.editMode) {
          this.addEditMarker()
        }
      }
      this.editDialog = false
      this.parent_id = null
      this.item_id = null
    },
    changeStartDate () {
      const sd = new Date(this.startDt).getTime()
      const ed = new Date(this.endDt).getTime()
      if (ed < sd) {
        this.endDt = this.startDt
      }
    },
    addComment () {
      // if (this.workflowComment) {
      //   this.workflow.next_data[this.workflowAction].comment = false
      //   this.processWorkflow(this.workflowAction)
      // }
      if (this.$store.state.bpm.workflowComment) {
        const action = this.$store.state.bpm.workflowAction
        this.$store.commit('bpm/SET_COMMENT_ALREADY_ADDED', action)
        this.$store.commit('bpm/RESET_COMMENT_STATE')
        this.$store.dispatch('bpm/processWorkflow', action)
      }
    },
    changeFields (value) {
      const _ = this
      value.forEach(function (item, i, arr) {
        _.plans[item.field] = item.val
      })
    },
    addTask (item) {
      this.$store.commit('task/SET_FROM_PLAN', item)
      this.$router.push({ name: 'TaskCreate' })
    },
    calcWidth () {
      if (!this.plan.months) return 0
      console.log(1080 + this.plan.months.length * 60)
      return 1080 + this.plan.months.length * 60
    }
  },
  filters: {
    mapRating (pure) {
      const map = new Map([
        ['mean', 'средняя'],
        ['quality', 'качества'],
        ['term', 'срока'],
        ['budget', 'бюджета'],
        ['main', 'общая']
      ])
      return `Оценка ${map.get(pure)}:`
    }
  }
}
</script>

<style scoped>
  .plan-header {
    font-weight: 600;
  }
  .plan-cell {
    border: thin solid rgba(200, 200, 200, 0.6);
    padding: 5px;
  }
  .plan-cell-main {
    border-top: 2px solid rgb(20, 20, 20);
  }
  .plan-month {
    width: 60px;
    text-align: center;
  }
  .plan-name {
    width: 720px;
    text-align: left;
    padding-left: 20px;
  }
  .plan-period {
    width: 150px;
    text-align: left;
    padding-left: 20px;
  }
  .plan-month-background {
    background-color: rgb(102, 255, 102);
  }
  .plan-month-background-light {
    background-color: rgb(204, 255, 204);
  }
  .plan-fact {
    width: 150px;
  }
  .plan-edit-background {
    background-color: #e6e2e2;
  }
</style>
