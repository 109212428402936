<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-briefcase"
      inline
      class="px-5 py-3"
      title="Контрольные точки проекта"
    >
      <v-row>
        <v-col cols="10">
          <project-navigation :user_id="-1"/>
<!--          <v-btn color="primary" class="ma-1" outlined small :to="`/projects/${project_id}/view`">Проект</v-btn>-->
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        disable-sort
        hide-default-footer
      >
        <template v-slot:item.appraisal_term="{ item }">
          <v-rating
            v-model="item.appraisal_term"
            :readonly="!(item.state === 3 && $auth.user().id === item.project.customer_id)"
            dense
            color="red"
            background-color="red lighten-4"
          ></v-rating>
        </template>
        <template v-slot:item.appraisal_budget="{ item }">
          <v-rating
            v-model="item.appraisal_budget"
            :readonly="!(item.state === 3 && $auth.user().id === item.project.customer_id)"
            dense
            color="red"
            background-color="red lighten-4"
          ></v-rating>
        </template>
        <template v-slot:item.appraisal_quality="{ item }">
          <v-rating
            v-model="item.appraisal_quality"
            :readonly="!(item.state === 3 && $auth.user().id === item.project.customer_id)"
            dense
            color="red"
            background-color="red lighten-4"
          ></v-rating>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-start">
            <v-btn
              text color="accent" dark class="mr-2" :to="`/projects/${project_id}/reports`"
              v-if="[3, 4, 5, 255].indexOf(item.state) !== -1"
              v-tooltip.bottom="`Просмотр отчета`"
            >Смотреть</v-btn>
            <v-btn
              text color="accent" dark class="mr-2" @click.stop="send({ id: item.id, pos: item.position, state: item.state })"
              v-if="item.state === 1 && $auth.user().id === item.whom_id"
              v-tooltip.bottom="`Создать отчет`"
            >Создать</v-btn>
            <v-btn
              text color="accent" dark class="mr-2" :to="`/projects/${project_id}/reports`"
              v-if="item.state === 2 && $auth.user().id === item.whom_id"
              v-tooltip.bottom="`Редактировать отчет`"
            >Редактировать</v-btn>
            <v-btn
              text color="accent" dark class="mr-2" @click.stop="send({ id: item.id, pos: item.position, state: item.state })"
              v-if="item.state === 2 && $auth.user().id === item.whom_id"
              v-tooltip.bottom="`Отправить отчет`"
            >Отправить</v-btn>
            <v-btn
              text color="accent" dark class="mr-2" @click.stop="send({ id: item.id, pos: item.position, state: item.state })"
              v-if="item.state === 3 && $auth.user().id === item.whom_id"
              :disabled="appraisalButtonDisabled(item.position)"
              v-tooltip.bottom="`Оценить этап`"
            >Оценить</v-btn>
          </div>
        </template>
      </v-data-table>
      <v-divider></v-divider>
    </base-card>
    <project-final-report v-if="finalReportVisible" :project_cp="items" @updateCP="updateCP"></project-final-report>
  </v-container>
</template>

<script>
import ProjectNavigation from '../../components/ProjectNavigation'
import ProjectFinalReport from '../../components/form/ProjectFinalReport'
export default {
  name: 'ControlPoint',
  components: { ProjectFinalReport, ProjectNavigation },
  data: () => ({
    headers: [
      { text: '', value: 'position' },
      { text: 'Дата', value: 'cp_dt' },
      // { text: 'Процент', value: 'percent' },
      { text: 'Оценка срока', value: 'appraisal_term' },
      { text: 'Оценка бюджета', value: 'appraisal_budget' },
      { text: 'Оценка качества', value: 'appraisal_quality' },
      { text: 'Действие', value: 'actions', width: '20%' }
    ],
    items: [],
    project_id: null
  }),
  created () {
    this.project_id = this.$route.params.id
  },
  mounted () {
    this.getData()
  },
  computed: {
    finalReportVisible: function () {
      if (!this.items) return false
      const length = this.items.length
      if (length < 1) return false
      if ([4, 5].indexOf(this.items[length - 1].state) !== -1 && this.$auth.user().id === this.items[length - 1].project.executor_id) return true
      return false
    }
  },
  methods: {
    async getData () {
      const data = await this.$store.dispatch('project/GET_CONTROL_POINT', this.project_id)
      this.items = data.cp
    },
    send (val) {
      const id = this.project_id
      const payload = {
        id: val.id,
        position: val.pos,
        appraisal_term: this.items[val.pos - 1].appraisal_term,
        appraisal_budget: this.items[val.pos - 1].appraisal_budget,
        appraisal_quality: this.items[val.pos - 1].appraisal_quality
      }
      this.$store.dispatch('project/POST_CONTROL_POINT', { id, payload })
        .then(response => {
          this.items = response.cp
          switch (val.state) {
            case 1:
              this.flashMessage.success({ title: 'Контрольная точка', message: 'Отчет создан и готов к редактированию' })
              break
            case 2:
              this.flashMessage.success({ title: 'Контрольная точка', message: 'Успешно отправлена на оценку' })
              break
            case 3:
              this.flashMessage.success({ title: 'Контрольная точка', message: 'Контрольная точка успешно оценена' })
          }
        })
    },
    appraisalButtonDisabled (pos) {
      if (this.items[pos - 1].appraisal_budget === 0 ||
        this.items[pos - 1].appraisal_quality === 0 ||
        this.items[pos - 1].appraisal_term === 0) return true
      return false
    },
    updateCP (items) {
      this.items = items
    }
  }
}
</script>

<style scoped>

</style>
